<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14.002 13.647" class="arrow">
    <path
      id="Caminho_816"
      data-name="Caminho 816"
      d="M5.953-11.909a.745.745,0,0,0,.013,1.072L9.731-7.25H.75A.748.748,0,0,0,0-6.5v1a.748.748,0,0,0,.75.75H9.731L5.966-1.163A.751.751,0,0,0,5.953-.091L6.647.6A.747.747,0,0,0,7.706.6l6.075-6.075a.747.747,0,0,0,0-1.059L7.706-12.6a.747.747,0,0,0-1.059,0Z"
      transform="translate(0 12.823)"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
enum Direction {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  Down = 'down',
}

export default {
  props: {
    color: {
      type: String,
      default: '#960b38',
    },

    width: {
      type: String,
      default: '13px',
    },

    height: {
      type: String,
      default: '12px',
    },

    direction: {
      type: String,
      default: Direction.Right,
    },
  },

  setup(props) {
    const directionArrow: ComputedRef<string> = computed(() => {
      if (props.direction == Direction.Right) return '0deg'
      if (props.direction == Direction.Down) return '90deg'
      if (props.direction == Direction.Left) return '180deg'
      return '270deg'
    })

    return {
      directionArrow,
    }
  },
}
</script>

<style lang="scss" scoped>
svg,
path {
  transition: 0.3s;
}

svg {
  transform: rotate(v-bind(directionArrow));
}
</style>
